var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center mt-10" }, [
      _c("a", { attrs: { href: "https://coreproductivity.net/" } }, [
        _c("img", {
          attrs: { src: "/media/logos/core-prod-logo.png", alt: "" },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }