<template>
  <div class="text-center mt-10">
    <a href="https://coreproductivity.net/">
      <img src="/media/logos/core-prod-logo.png" alt="" />
    </a>
  </div>
</template>

<script>
export default {
  name: 'hakkimizda-sayfasi',
  computed: {},
  data() {
    return {}
  },
  mounted() {},
  methods: {}
}
</script>
